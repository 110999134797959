import CustomizedButtonsList from '../classes/CustomizedButtonsList';

export default function customizedButtons() {
  const DEFAULT_SELECTOR = '[data-customized-button="default"]';
  const SEARCH_SELECTOR = '[data-customized-button="search"]';
  const PLUS_SELECTOR = '[data-customized-button="plus"]';
  const DYNAMIC_LINK_SELECTOR = '.js-cms-content a:not(.primary-button):not(.secondary-button):not([target=_blank]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]):not(.docs)';
  const DYNAMIC_BUTTON_SELECTOR = '.js-cms-content a.primary-button:not([target=_blank]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]), .js-cms-content a.secondary-button:not([target=_blank]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const DYNAMIC_EXTERNAL_BUTTON_SELECTOR = '.js-cms-content a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const DYNAMIC_DOWNLOAD_BUTTON_SELECTOR = '.js-cms-content a[href$=".pdf"], .js-cms-content a[href$=".doc"], .js-cms-content a[href$=".zip"], .js-cms-content a.docs';

  const LISTS = [{
    selector: DEFAULT_SELECTOR,
  }, {
    selector: SEARCH_SELECTOR,
    options: {
      svgName: 'search',
    },
  }, {
    selector: PLUS_SELECTOR,
    options: {
      svgName: 'plus',
    },
  }, {
    selector: DYNAMIC_LINK_SELECTOR,
  }, {
    selector: DYNAMIC_BUTTON_SELECTOR,
  }, {
    selector: DYNAMIC_EXTERNAL_BUTTON_SELECTOR,
    options: {
      svgName: 'external',
    },
  }, {
    selector: DYNAMIC_DOWNLOAD_BUTTON_SELECTOR,
    options: {
      svgName: 'download',
    },
  }];

  LISTS.forEach((list) => {
    const CUSTOMIZED_BUTTONS_LIST = new CustomizedButtonsList(list.selector, list.options);
    CUSTOMIZED_BUTTONS_LIST.init();
  });
}
