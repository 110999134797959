import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import CharacterCount from '@blankonumerique/blanko-scripts/character-count';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import { calendar, backendKeyBind, ogContent, setstripHeightCSSVariable, listLoaded, adresseSearchResultsLoaded, formSearchByAddressAutocomplete } from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import { resetDropzone, formsPackage, tailSelect } from './functions/form';
import { overlayMenu, overlayShare } from './functions/overlays';
import Overlay from './vendor/overlay.min';
import { formAccessibility, formContact, formExample, formPlainte, formSuccess } from './functions/validation';
import masksPackage from './functions/masks';
import { hundredVH, removeHoverOnMobile } from './functions/helper';
import tippyTest from './functions/tooltip';
import customizedButtons from './functions/customizedButtons';
import bottomRightStickyThings from './functions/bottom-right-sticky-things';
import { swiperServices, swiperFilter, swiperHomeBanner } from './functions/sliders';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.listLoaded = listLoaded;
window.adresseSearchResultsLoaded = adresseSearchResultsLoaded;
window.customizedButtons = customizedButtons;
window.dynamicPackage = dynamicPackage;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    overlayMenu();
    overlayShare();
    swiperFilter();
    customizedButtons();
    backendKeyBind();
    ShareButtons.update();
    SCROLLFIRE.init();
    hundredVH();
    bottomRightStickyThings();
    removeHoverOnMobile();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        async beforeEnter() {
          await Scroller.init();
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          formContact();
          calendar();
          tippyTest();
          tailSelect();
          formPlainte();
          formAccessibility();
          CharacterCount.init({ className: 'u-text' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        async beforeEnter() {
          await Scroller.init();
          setstripHeightCSSVariable();
          dynamicPackage();
          swiperServices();
          swiperHomeBanner();
          CharacterCount.init({ className: 'u-text' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'addrSearchView',
        async beforeEnter() {
          await Scroller.init();
          rubrics();
          tailSelect();
          dynamicPackage();
          formSearchByAddressAutocomplete();
          CharacterCount.init({ className: 'u-text' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          dynamicPackage();
          CharacterCount.init({ className: 'u-text' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
