// |--------------------------------------------------------------------------
// | Back to top and links
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner un bloc
// | sticky du back to top.
// |
// | IMPORTS _____
// | -> Observer
// | -> clickToScrollToBlock
// |
// | OPTIONS _____
// | container => Le container sticky.
// | distance => La distance en pixels avant que le contenu invisible soit
// |             visible.
// | showClass => La classe ajoutée pour rendre visible le contenu caché.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------

import { OBSERVER } from '../plugins';
// ---------------------------------------------------------------------------

export default function bottomRightStickyThings(params = {}) {
  // Déclaration des propriétés par défaut
  const options = {};
  options.container = params.container === undefined ? '.js-bottom-right-sticky-things' : params.container;
  options.distance = params.distance === undefined ? 500 : params.distance;
  options.showClass = params.showClass === undefined ? 'switchable-state-content-is-visible' : params.showClass;

  // Déclarations des variables
  const container = document.querySelector(options.container);

  // Déclarations des fonctions
  const manageBackToTop = () => {
    if (document.documentElement.scrollTop >= options.distance) {
      container.classList.add(options.showClass);
    } else {
      container.classList.remove(options.showClass);
    }
  };

  const onResize = () => {
    const height = document.querySelector('.js-bottom-right-sticky-things-container').offsetHeight;
    document.documentElement.style.setProperty('--bottom-right-sticky-things-height', `${height}px`);
  };

  // Si le container existe
  if (typeof container !== 'undefined' && container !== null) {
    // Appel de la fonction au moins une fois
    manageBackToTop();
    onResize();

    // Ajout de l événement pour masquer ou afficher le contenu
    OBSERVER.add({
      name: 'manageBackToTop',
      events: 'resize',
      function: onResize,
    });
    OBSERVER.add({
      name: 'manageBackToTop',
      events: 'scroll',
      function: manageBackToTop,
    });
    OBSERVER.on('manageBackToTop');
  }
}
