// Englobe les iframe de vidéos youtube pour gérer le ratio
function wrapYtVideo() {
  let i;
  let youtubeVideosOuterHtml;
  let newYoutubeVideosOuterHtml;
  const youtubeVideos = document.querySelectorAll('.js-cms-content iframe[src*="youtube.com"],.js-cms-content iframe[src*="vimeo.com"]');
  const youtubeVideosLength = youtubeVideos.length;

  for (i = 0; i < youtubeVideosLength; i += 1) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML;
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`;
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml;
  }
}

// Ajouter des marges pour les <p> qui contiennent des boutons
function adjustButtonsMargins() {
  let i;
  const buttons = document.querySelectorAll('.js-cms-content p > a.primary-button, .js-cms-content p > a.secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) { buttons[i].parentNode.classList.add('buttons'); }

  const buttonsParagraph = document.querySelectorAll('.js-cms-content p.buttons');
  const buttonsParagraphLength = buttonsParagraph.length;

  for (i = 0; i < buttonsParagraphLength; i += 1) {
    if (buttonsParagraph[i].previousElementSibling !== null) {
      if (!buttonsParagraph[i].previousElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--first');
      }
    }
    if (buttonsParagraph[i].nextElementSibling !== null) {
      if (!buttonsParagraph[i].nextElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--last');
      }
    }
  }
}

// Ajouter les icones svg pour les boutons et les liens textes
function manageImageLinks() {
  const links = document.querySelectorAll('.js-cms-content a');
  links.forEach((link) => {
    if (link.querySelector('img')) {
      link.classList.add('link-image');
    }
  });
}

// Le package à appliquer pour les zones dynamiques
export default function dynamicPackage() {
  wrapYtVideo();
  adjustButtonsMargins();
  manageImageLinks();
}
