import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'jquery-validation';
import 'jquery-mask-plugin';
import 'jquery-validation/dist/additional-methods';
import './barba';
import 'devbridge-autocomplete';

window.jQuery = $;
window.$ = $;
