import Swiper from 'swiper/bundle';

export function swiperHomeBanner() {
  const FIRST_NAME = 'home-banner-content';
  const SECOND_NAME = 'home-banner-picture';

  if (document.querySelector(`[data-swiper="${FIRST_NAME}"]`)) {
    window.swiperHomeBannerContent = new Swiper(`[data-swiper="${FIRST_NAME}"]`, {
      autoplay: {
        delay: 4000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      pagination: {
        el: `[data-swiper-pagination="${FIRST_NAME}"]`,
        clickable: true,
      },
      slidesPerView: 1,
      speed: 700,
      threshold: 10,
    });
  }

  if (document.querySelector(`[data-swiper="${SECOND_NAME}"]`)) {
    window.swiperHomeBannerImages = new Swiper(`[data-swiper="${SECOND_NAME}"]`, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      slidesPerView: 1,
      speed: 700,
      threshold: 10,
    });
  }

  if (window.swiperHomeBannerContent && window.swiperHomeBannerImages) {
    window.swiperHomeBannerContent.controller.control = window.swiperHomeBannerImages;
    window.swiperHomeBannerImages.controller.control = window.swiperHomeBannerContent;
  }
}

export function swiperServices() {
  const NAME = 'services';

  if (document.querySelector(`[data-swiper="${NAME}"]`)) {
    window.swiperFilter = new Swiper(`[data-swiper="${NAME}"]`, {
      slidesPerView: 'auto',
      threshold: 10,
      speed: 600,
      loop: true,
      navigation: {
        prevEl: `[data-swiper-prev="${NAME}"]`,
        nextEl: `[data-swiper-next="${NAME}"]`,
        disabledClass: 'is-disabled',
      },
    });
  }
}

export function swiperFilter() {
  const NAME = 'filters';

  if (document.querySelector(`[data-swiper="${NAME}"]`)) {
    window.swiperFilter = new Swiper(`[data-swiper="${NAME}"]`, {
      slidesPerView: 'auto',
      spaceBetween: 6,
      threshold: 10,
      speed: 300,
      freeMode: true,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: `[data-swiper-prev="${NAME}"]`,
        nextEl: `[data-swiper-next="${NAME}"]`,
        disabledClass: 'is-disabled',
      },
    });
  }
}
