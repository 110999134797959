import $ from 'jquery';
import LazyLoad from 'vanilla-lazyload';
import { Datepicker } from 'vanillajs-datepicker';
import { OBSERVER } from '../plugins';
import rubrics from './rubrics'
import Scroller from '@blankonumerique/blanko-scripts/scroller';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function setstripHeightCSSVariable() {
  const ELEMENT = document.querySelector('.c-strip');

  if (ELEMENT) {
    let height;

    const setHeight = () => {
      height = ELEMENT.offsetHeight;
      document.documentElement.style.setProperty('--strip-height', `${height}px`);
    };

    setHeight();

    OBSERVER.add({
      name: 'setstripHeightCSSVariable',
      events: 'resize',
      targets: 'window',
      function: setHeight,
    });
    OBSERVER.on('setstripHeightCSSVariable');
  }
}

export function listLoaded(data) {
  const buttonContainer = document.querySelector('#listMoreBtn');

  window.lazyload = new LazyLoad();

  if (!data['hasMorePages'])
    buttonContainer.style.display = 'none';
  else {
    buttonContainer.style.display = 'block';
  }
}

export function adresseSearchResultsLoaded() {
  rubrics();
  Scroller.init();
}

// Fonction permettant de suggérer des valeurs pour les champs du formulaire de recherche par adresse
export function formSearchByAddressAutocomplete() {
  const targetFieldCivicNumberWrapper   = '#form-search-by-address-wrapper--civic-number'
  const targetFieldStreetWrapper        = '#form-search-by-address-wrapper--street'
  const targetFieldCivicNumber          = '#form-search-by-address-input--civic-number'
  const targetFieldStreet               = '#form-search-by-address-input--street'

  var numberAutocompleteOptions = {
    noCache: true,
    params: { 'civic-number' : '' },
    serviceUrl: '/blanko/addrsearch/autocomplete/civic',
    appendTo: targetFieldCivicNumberWrapper
  }

  $(targetFieldCivicNumber).autocomplete(numberAutocompleteOptions)

  $(targetFieldStreet).autocomplete({
    serviceUrl: '/blanko/addrsearch/autocomplete/street',
    appendTo: targetFieldStreetWrapper,
    onSelect: function (suggestion) {
      numberAutocompleteOptions.params = { 'street' : $(targetFieldStreet).val() }
      $(targetFieldCivicNumber).autocomplete().setOptions(numberAutocompleteOptions)
    }
  })

  $(targetFieldStreet).keyup( function() {
    numberAutocompleteOptions.params = { 'street' : $(targetFieldStreet).val() }
    $(targetFieldCivicNumber).autocomplete().setOptions(numberAutocompleteOptions)
  })
}
