import Accordions from '../classes/accordions';
import { OBSERVER } from '../plugins';

// Permet d'initialiser les rubrics avec un offset dynamique ou sans de offset
// Pour un offset dynamique, on doit ajouter des fonctions (ex: onResize, onCloseAlert) pour changer le offset
export default function rubrics() {
  const rubric = new Accordions();
  rubric.offset = 0;
  rubric.init();

  const button1 = document.querySelector('.js-rubric-soutien');
  const button2 = document.querySelector('.js-rubric-service');

  const setClickEvent = (button, destination) => {
    if (button === null || destination === null) return;

    OBSERVER.add({
      name: 'rubrics',
      events: 'click',
      targets: button,
      function: () => {
        rubric.closeAll();
        rubric.open(destination);
      },
    });
    OBSERVER.on('rubrics');
  };

  setClickEvent(button1, document.querySelector('#accordion-soutien-technique'));
  setClickEvent(button2, document.querySelector('#accordion-service-la-clientele'));
}
