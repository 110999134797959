import CustomizedButton from './CustomizedButton';

export default class CustomizedButtonsList {
  // CONSTRUCTOR
  constructor(selector, options = {}) {
    this.customizedButtons = [];

    this.selector = selector;

    this.options = options;
    this.setDefaultOptions();
  }

  // PRIVATE
  // Attribution des valeurs par défaut des options de la classe
  setDefaultOptions() {
    this.options.initializationClassName = this.options.initializationClassName || 'js-customized-button-initialized';
  }

  // PUBLIC
  // Initialise la liste
  init() {
    this.buttons = document.querySelectorAll(this.selector);

    this.buttons.forEach((button) => {
      if (!button.classList.contains(this.options.initializationClassName)) {
        const CUSTOMIZED_BUTTON = new CustomizedButton(button, this.options);
        CUSTOMIZED_BUTTON.init();
        this.customizedButtons = [...this.customizedButtons, CUSTOMIZED_BUTTON];
      }
    });

    CustomizedButtonsList.instances = [...CustomizedButtonsList.instances, this];
  }

  // Permet d'actualiser toutes les listes des boutons existantes
  static update() {
    CustomizedButtonsList.instances.forEach((instance) => {
      const INSTANCE = instance;
      INSTANCE.buttons = document.querySelectorAll(INSTANCE.selector);

      INSTANCE.buttons.forEach((button) => {
        if (!button.classList.contains(CustomizedButtonsList.instance.options.initializationClassName)) {
          const CUSTOMIZED_BUTTON = new CustomizedButton(button, INSTANCE.options);
          CUSTOMIZED_BUTTON.init();
          INSTANCE.customizedButtons = [...INSTANCE.customizedButtons, CUSTOMIZED_BUTTON];
        }
      });
    });
  }
}

CustomizedButtonsList.instances = [];
